import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import ApiServices from '../../../../../service/apiServices';
import Titulares from '../../../../../refactor/PagoFolio/PagoResulFolio/Folio/Titulares/Titulares';

interface City {
    name: string;
}

const TabTitulares = (props: any) => {
    const [titulares, setTitulares] = useState([])

    const getTitulares = () => {
        let datos = {
            prop_id: props.generalData.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getTitulares()
    }, []);

    return (
        <section>
            <Titulares data={{ titulares: titulares }} />
        </section>
    );
}

export default React.memo(TabTitulares);