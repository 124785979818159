import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/apiServices';
import authGuardService from '../../service/authGuardService';
import { Dropdown } from 'primereact/dropdown';

const NuevoFolioTitulares = (props: any) => {
    const user = authGuardService.getUser()

    const columns = [
        { field: 'RowNumber', header: 'Reg' },
        { field: 'prop_clave', header: 'Clave' },
        { field: 'prop_folio', header: 'Folio' },
        { field: 'prop_descripcion', header: 'Dirección' },
        { field: 'prop_lt', header: 'Lt' },
        { field: 'prop_mz', header: 'Mz' },
    ];

    const [titulares, setTitulares] = useState([])
    const [nuevoFolioModal, setNuevoFolioModal] = useState(false)
    const [allFolios, setAllFolios] = useState([])
    const [selectedFolio, setSelectedFolio] = useState(null)
    const [selectedFolioInner, setSelectedFolioInner] = useState<any>()
    const [selectedCP, setSelectedCP] = useState<any>(null)
    const [estado, setEstado] = useState('')
    const [municipio, setMunicipio] = useState('')
    const [cp, setCp] = useState('')

    const getTitulares = () => {
        let datos = {
            frmu_id: props && props.information && props.information.frmu_id,
        }
        ApiServices.apiPost('getPropiedadDT', datos).then(async res => {
            if (res.data.addenda && res.data.addenda.length > 0) {
                const formatted = res.data.addenda.map((singleTitular, index) => {
                    singleTitular.temporalID = index + 1000
                    singleTitular.ptit_status = 1
                    return singleTitular
                })
                props.handleValueChange(formatted, props.indexes[0], props.indexes[1])
                setTitulares(formatted)
            }

            handleMultipleFoliosValues()
        }).catch(error => {

        })
    }

    const addNewTitular = () => {
        if (titulares.length > 0) {
            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.temporalID = titulares.length + 1000
            emptyObject.prop_folio = selectedFolio.prop_folio
            emptyObject.usu_id = user.usu_id.value
            emptyObject.frmu_id = props.information.frmu_id
            emptyObject.prop_antecedente = selectedFolio && selectedFolio.prop_antecedente ? selectedFolio.prop_antecedente : 'PRIMER ANTECEDENTE'
            emptyObject.RowNumber = titulares.length + 1
            emptyObject.ptit_status = 1

            emptyObject.cp_id = selectedFolio && selectedFolio.cp_id ? selectedFolio.cp_id : ''
            emptyObject.cp_cp = selectedFolio && selectedFolio.cp_cp ? selectedFolio.cp_cp : ''
            emptyObject.cp_edo = selectedFolio && selectedFolio.cp_edo ? selectedFolio.cp_edo : ''
            emptyObject.cp_mun = selectedFolio && selectedFolio.cp_mun ? selectedFolio.cp_mun : ''

            setTitulares([...titulares, emptyObject]);
            props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
        } else {
            const newTitular = {
                temporalID: titulares.length + 1000,
                prop_folio: selectedFolio.prop_folio,
                frmu_id: props.information.frmu_id,
                usu_id: user.usu_id.value,
                prop_antecedente: selectedFolio && selectedFolio.prop_antecedente ? selectedFolio.prop_antecedente : 'PRIMER ANTECEDENTE',
                prop_clave: '',
                RowNumber: titulares.length + 1,
                cp_id: selectedFolio && selectedFolio.cp_id ? selectedFolio.cp_id : '',
                cp_cp: selectedFolio && selectedFolio.cp_cp ? selectedFolio.cp_cp : '',
                prop_calle: '',
                prop_ext: '',
                prop_int: '',
                prop_medidas: '',
                prop_constr: '',
                prop_suptotal: '',
                prop_zona: '',
                prop_condo: '',
                prop_descripcion: '',
                prop_lote_cond: '',
                prop_lt: '',
                prop_edificio: '',
                prop_libro: '',
                prop_vol: '',
                prop_secc: '',
                prop_serie: '',
                prop_reg: '',
                prop_foja: '',
                prop_mz: '',
                cp_edo: selectedFolio && selectedFolio.cp_edo ? selectedFolio.cp_edo : '',
                cp_mun: selectedFolio && selectedFolio.cp_mun ? selectedFolio.cp_mun : '',
                prop_direccion: '',
                prop_indiviso: '',
                prop_ususuelo: '',
                ptit_status: 1
            }
            setTitulares([...titulares, newTitular]);
            props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
        }
    }

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
    };

    const handleNewFolioTitular = (data: any) => {
        setSelectedFolioInner(data)
        setNuevoFolioModal(true)
        if (data && data.cp_cp) {
            getColonyWithPostalCode(data.cp_cp, data)
        } else if (props && props.propiedadDetail && props.propiedadDetail.cp_cp) {
            getColonyWithPostalCode(props.propiedadDetail.cp_cp, data)
        } else if (props && props.propiedadDetail && props.propiedadDetail.cp_mun) {
            setEstado(props.propiedadDetail.cp_edo)
            setMunicipio(props.propiedadDetail.cp_mun)
            setCp(props.propiedadDetail.cp_cp)
        }
    }

    const getColonyWithPostalCode = (value: any, information = selectedFolioInner) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {

                const clone = JSON.parse(JSON.stringify(information))

                setSelectedCP(res.data.addenda[0])
                setEstado(res.data.addenda[0].cp_edo)
                setMunicipio(res.data.addenda[0].cp_mun)
                setCp(res.data.addenda[0].cp_cp)

                clone.cp_mun = selectedFolio.cp_mun
                clone.cp_edo = selectedFolio.cp_edo
                clone.cp_id = selectedFolio.cp_id
                clone.cp_cp = selectedFolio.cp_cp

                setSelectedFolioInner(clone)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.propdt_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.propdt_id == 0 || !data.propdt_id) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 0
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const saveDataInsideArray = (titularesNew = titulares) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titularesNew))
        const newTitulares = cloneTitulares.map((singleTitular) => {
            if (singleTitular.temporalID == selectedFolioInner.temporalID) {
                /* if (selectedCP) {
                    setEstado('')
                    setMunicipio('')
                    setCp('')
                } */
                return selectedFolioInner
            } else {
                return singleTitular
            }
        })

        setTitulares(newTitulares)
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
        setSelectedFolioInner(null)
        setNuevoFolioModal(false)
    }

    const handleData = (value, field) => {
        const clone = JSON.parse(JSON.stringify(selectedFolioInner))
        clone[field] = value
        setSelectedFolioInner(clone)
    }

    const handleCPChange = (cp: any) => {
        if (cp.length === 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const handleMultipleFoliosValues = async () => {
        let newChilds = []
        let newFather = props?.tramite?.tramite
        if (newFather && newFather.prop_id) {
            let datos = {
                prop_id: newFather.prop_id,
                prop_online: 0
            }
            await ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                if (res.data.valido === 1) {
                    newFather.extra = res.data.addenda[0]
                }
            }).catch(error => {
                console.log(error)
            })
            newFather = {
                ...newFather,
                ...newFather.extra
            }
        }

        newChilds.push(newFather)

        if (props && props.tramite && props.tramite.hijos) {
            for (const element of props.tramite.hijos) {
                let datos = {
                    prop_id: element.prop_id,
                    prop_online: 0
                }
                await ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                    if (res.data.valido === 1) {
                        element.extra = res.data.addenda[0]
                    }
                }).catch(error => {
                    console.log(error)
                })
                const newElement = {
                    ...element,
                    ...element.extra
                }
                newChilds.push(newElement)
            }
        }

        setAllFolios(newChilds)
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            {props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0 ?
                <Dropdown
                    value={selectedFolio}
                    onChange={(e) => setSelectedFolio(e.value)}
                    options={allFolios}
                    optionLabel="prop_folio"
                    placeholder="Folio"
                />
                : <span className="text-xl text-900 font-bold"></span>
            }
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    const isDeletedTemplate = (rowData, index) => {
        if (rowData && rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminará"></Tag>
        else return ''
    };

    const addFolioTemplate = (e) => (
        <Button label="Editar Folio" severity="secondary" rounded onClick={() => { handleNewFolioTitular(e) }} />
    );

    const textEditor = (options: ColumnEditorOptions) => {
        if (options.field == 'ptit_nuda' || options.field == "ptit_usufructo" || options.field == 'nuda_enaje' || options.field == 'usufructo_enaje') {
            return (
                <InputNumber
                    value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} minFractionDigits={2} min={0} max={100} />
            )
        } else if (options.field == 'prop_descripcion') {
            return (
                <InputTextarea
                    value={options.value}
                    onChange={(e) => options.editorCallback(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    rows={5}
                    cols={30}
                    style={{ resize: 'none' }}
                />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    const deleteFolioTemplate = (rowData, index) => {
        const [visible, setVisible] = useState(false);
        const toast = useRef(null);
        const buttonEl = useRef(null);

        if (rowData && rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return (
                <>
                    <ConfirmPopup
                        target={buttonEl.current}
                        visible={visible}
                        onHide={() => setVisible(false)}
                        message="¿Estás seguro de eliminar?"
                        acceptLabel='Eliminar'
                        rejectLabel='Cancelar'
                        icon="pi pi-exclamation-triangle"
                        accept={() => { deleteTitular(rowData, index.rowIndex) }}
                    />

                    <Button
                        type="button"
                        severity="danger"
                        icon="pi pi-trash"
                        ref={buttonEl}
                        rounded onClick={() => { setVisible(true) }}>
                    </Button>
                </>
            );
    };

    useEffect(() => {
        getTitulares()
    }, [])

    useEffect(() => {
        if (!selectedFolio) setSelectedFolio(allFolios[0])
    }, [allFolios])

    const visibleData = titulares.filter((item) => item?.prop_folio == selectedFolio?.prop_folio)

    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={visibleData}
                        dataKey="temporalID"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        emptyMessage="No hay registros."
                        showGridlines
                        header={header}
                    >
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />
                        {
                            columns.map(({ field, header }) => {
                                return <Column
                                    key={field}
                                    field={field}
                                    header={header}
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                                />;
                            })
                        }
                        <Column body={addFolioTemplate}></Column>
                        <Column body={deleteFolioTemplate}></Column>
                    </DataTable>
                </div>
            </Panel>

            <Dialog
                header="Folio Real"
                visible={nuevoFolioModal}
                style={{ width: '50vw' }}
                onHide={() => { saveDataInsideArray(titulares) }}
                footer={
                    <div>
                        <Button label="Guardar y Cerrar" onClick={() => { saveDataInsideArray(titulares) }} autoFocus />
                    </div>
                }
            >

                <section className=''>
                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Datos Generales</h5>
                        </div>

                        <Divider />

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_clave"
                                    className="p-inputtext-lg"
                                    placeholder="Clave catastral"
                                    value={selectedFolioInner && selectedFolioInner.prop_clave}
                                    onChange={(e) => { handleData(e.target.value, "prop_clave") }}
                                />
                                <label htmlFor="cvecatastral">Clave catastral *</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_antecedente"
                                    className="p-inputtext-lg"
                                    placeholder="Antecedentes"
                                    value={selectedFolioInner && selectedFolioInner.prop_antecedente}
                                    onChange={(e) => { handleData(e.target.value, "prop_antecedente") }}
                                />
                                <label htmlFor="cvecatastral">Antecedentes *</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputTextarea
                                    id="prop_direccion"
                                    className="p-inputtext-lg"
                                    placeholder="Domicilio registral"
                                    value={selectedFolioInner && selectedFolioInner.prop_direccion}
                                    onChange={(e) => { handleData(e.target.value, "prop_direccion") }}
                                    rows={5}
                                    cols={30}
                                    style={{ resize: 'none' }}
                                />
                                <label htmlFor="prop_direccion">Domicilio registral</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_cp"
                                    className="p-inputtext-lg"
                                    value={selectedFolioInner && selectedFolioInner.cp_cp}
                                /* onChange={(e) => {
                                    handleCPChange(e.target.value);
                                    setCp(e.target.value)
                                }} */
                                />
                                <label htmlFor="cp_cp">Codigo postal</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_edo"
                                    className="p-inputtext-lg"
                                    value={selectedFolioInner && selectedFolioInner.cp_edo}
                                    disabled
                                />
                                <label htmlFor="cp_edo">Estado</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_mun"
                                    className="p-inputtext-lg"
                                    value={selectedFolioInner && selectedFolioInner.cp_mun}
                                />
                                <label htmlFor="cp_mun">Municipio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_lt"
                                    className="p-inputtext-lg"
                                    placeholder="Lt"
                                    value={selectedFolioInner && selectedFolioInner.prop_lt}
                                    onChange={(e) => { handleData(e.target.value, "prop_lt") }}
                                />
                                <label htmlFor="prop_lt">Lt</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_mz"
                                    className="p-inputtext-lg"
                                    placeholder="Mz"
                                    value={selectedFolioInner && selectedFolioInner.prop_mz}
                                    onChange={(e) => { handleData(e.target.value, "prop_mz") }}
                                />
                                <label htmlFor="prop_mz">Mz</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_zona"
                                    className="p-inputtext-lg"
                                    placeholder="Zona*"
                                    value={selectedFolioInner && selectedFolioInner.prop_zona}
                                    onChange={(e) => { handleData(e.target.value, "prop_zona") }}
                                />
                                <label htmlFor="prop_zona">Zona*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_indiviso"
                                    className="p-inputtext-lg"
                                    placeholder="Indiviso*"
                                    value={selectedFolioInner && selectedFolioInner.prop_indiviso}
                                    onChange={(e) => { handleData(e.target.value, "prop_indiviso") }}
                                />
                                <label htmlFor="prop_indiviso">Indiviso</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_ususuelo"
                                    className="p-inputtext-lg"
                                    placeholder="Uso de Suelo*"
                                    value={selectedFolioInner && selectedFolioInner.prop_ususuelo}
                                    onChange={(e) => { handleData(e.target.value, "prop_ususuelo") }}
                                />
                                <label htmlFor="prop_ususuelo">Uso de Suelo</label>
                            </span>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Superficie del predio</h5>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_suptotal"
                                    className="p-inputtext-lg"
                                    placeholder="Total m2"
                                    value={selectedFolioInner && selectedFolioInner.prop_suptotal}
                                    onChange={(e) => { handleData(e.target.value, "prop_suptotal") }}
                                />
                                <label htmlFor="prop_suptotal">Total m2</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_constr"
                                    className="p-inputtext-lg"
                                    placeholder="construido m2"
                                    value={selectedFolioInner && selectedFolioInner.prop_constr}
                                    onChange={(e) => { handleData(e.target.value, "prop_constr") }}
                                />
                                <label htmlFor="prop_constr">construido m2</label>
                            </span>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Medidas y Colindancias</h5>
                        </div>
                        <div className='col-12 md:col-12'>
                            <InputTextarea
                                id="prop_medidas"
                                className="p-inputtext-lg"
                                value={selectedFolioInner && selectedFolioInner.prop_medidas}
                                onChange={(e) => { handleData(e.target.value, "prop_medidas") }}
                            />
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Domicilio convencional</h5>
                        </div>

                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_calle"
                                    className="p-inputtext-lg"
                                    placeholder="Calle*"
                                    value={selectedFolioInner && selectedFolioInner.prop_calle}
                                    onChange={(e) => { handleData(e.target.value, "prop_calle") }}
                                />
                                <label htmlFor="prop_calle">Calle*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_int"
                                    className="p-inputtext-lg"
                                    placeholder="N°. interior"
                                    value={selectedFolioInner && selectedFolioInner.prop_int}
                                    onChange={(e) => { handleData(e.target.value, "prop_int") }}
                                />
                                <label htmlFor="prop_int">N°. interior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_ext"
                                    className="p-inputtext-lg"
                                    placeholder="N°. exterior"
                                    value={selectedFolioInner && selectedFolioInner.prop_ext}
                                    onChange={(e) => { handleData(e.target.value, "prop_ext") }}
                                />
                                <label htmlFor="prop_ext">N°. exterior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_condo"
                                    className="p-inputtext-lg"
                                    placeholder="Condominio"
                                    value={selectedFolioInner && selectedFolioInner.prop_condo}
                                    onChange={(e) => { handleData(e.target.value, "prop_condo") }}
                                />
                                <label htmlFor="prop_condo">Condominio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_lote_cond"
                                    className="p-inputtext-lg"
                                    placeholder="Lote condominal"
                                    value={selectedFolioInner && selectedFolioInner.prop_lote_cond}
                                    onChange={(e) => { handleData(e.target.value, "prop_lote_cond") }}
                                />
                                <label htmlFor="prop_lote_cond">Lote condominal</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_edificio"
                                    className="p-inputtext-lg"
                                    placeholder="Edificio"
                                    value={selectedFolioInner && selectedFolioInner.prop_edificio}
                                    onChange={(e) => { handleData(e.target.value, "prop_edificio") }}
                                />
                                <label htmlFor="prop_edificio">Edificio</label>
                            </span>
                        </div>


                    </div>
                </section>

            </Dialog>

        </section >
    );

}

export default React.memo(NuevoFolioTitulares);