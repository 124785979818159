import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';

interface City {
    name: string;
}

const TabGenerales = (props: any) => {

    const generalData = props.generalData

    return (
        <section className=''>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio</small>
                    <p className='result'>{generalData ? generalData.prop_folio : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Clave catastral</small>
                    <p className='result'>{generalData ? generalData.prop_clave : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Antecedentes</small>
                    <p className='result'>{generalData?.prop_antecedente ? generalData.prop_antecedente : ''}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Domicilio registral</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio registral</small>
                    <p className='result'>{generalData ? generalData.prop_direccion : ''}</p>
                </div>
                <div className='col-12'>
                    <h5>Domicilio convencional</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Codigo postal</small>
                    <p className='result'>{generalData ? generalData.cp_cp : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Localidad</small>
                    <p className='result'>{generalData ? generalData.cp_loc : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{generalData ? generalData.cp_mun : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{generalData ? generalData.cp_edo : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Colonia</small>
                    <p className='result'>{generalData ? generalData.cp_col : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. interior</small>
                    <p className='result'>{generalData ? generalData.prop_int : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. exterior</small>
                    <p className='result'>{generalData ? generalData.ext : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Antecedentes Registrales</small>
                    <p className='result'>{generalData ? generalData.prop_antecedente : ''}</p>
                </div>

                <div className='col-12 md:col-4'>
                    <small className='black'>Indiviso</small>
                    <p className='result'>{generalData ? generalData.prop_indiviso : ''}</p>
                </div>

                <div className='col-12 md:col-4'>
                    <small className='black'>Uso Suelo</small>
                    <p className='result'>{generalData ? generalData.prop_ususuelo : ''}</p>
                </div>

                <div className='col-12 md:col-4'>
                    <small className='black'>Restante</small>
                    <p className='result'>{generalData ? generalData.prop_suprestante : ''}</p>
                </div>

                <div className='col-12 md:col-4'>
                    <small className='black'>Total</small>
                    <p className='result'>{generalData ? generalData.prop_suptotal : ''}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Superficie del predio</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas y Colindancias</small>
                    <p className='result'>{generalData ? generalData.prop_medidas : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Construidas</small>
                    <p className='result'>{generalData ? generalData.prop_constr : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Superficie total</small>
                    <p className='result'>{generalData ? generalData.prop_suptotal : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas</small>
                    <p className='result'>{generalData ? generalData.prop_medidas_uno : ''}</p>
                </div>
            </div>

        </section>
    );

}

export default React.memo(TabGenerales);